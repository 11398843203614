/*=========================Begin Provider Directory CSS=========================================================================================*/

/* Existing styles */
.providerdirectory-header {
  font-size: 2rem;
  font-weight: 500 !important;
  margin-bottom: 35px;
  width: auto;
}

.providerdirectory-green-underline {
  height: 5px;
  bottom: 0 !important;
  content: "";
  background: #50d39f;
  width: auto;
}

.selected {
  fill: none;
  stroke: white;
  stroke-width: 2px;
  pointer-events: none; /* Ensure clicks pass through to the underlying map */
}

.svg-overlay {
  pointer-events: none;
}

.form-group input[type="checkbox"] {
  border: 2px solid #000;
}

.provider-search-error {
  color: red;
  margin-left: 5px; /* Move the text 5 pixels to the right */
}

/* ProviderOptions component */
.provider-options-container {
  padding: 10px;
  margin: 5px 0;
}

.provider-options-section {
  padding: 10px;
  margin: 5px 0;
}

.provider-options-select {
  width: auto;
  margin-bottom: 10px;
}

.provider-options-sub-section {
  margin-top: 10px;
}

/* ProviderMap component */
/* Hide KY State Image Map on mobile */
@media (max-width: 768px) {
  .image-map {
    display: none;
  }
}

.provider-map-section {
  padding: 10px;
  margin: 5px 0;
}

.county-area {
  cursor: pointer;
}

.county-select {
  width: auto;
  height: 150px;
}

.statewide-selection, .county-selection {
  color: black;
  font-weight: bold;
}

.form-actions {
  text-align: center;
  margin: 20px 0;
}

.validation-message, .no-results-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

/* Tooltip styles for ProviderOptions and ProviderMap components */
.tooltip-custom .tooltip-inner {
  background-color: #384d60;
  color: white;
}

.tooltip-custom .arrow::before {
  background-color: #384d60;
}

/* Tooltip Icon */
.info-icon {
  background-color: #384d60 !important;
  color: white !important;
  padding: 2px !important;
  border-radius: 2px !important; 
  display: inline-block !important;
  width: 14px !important;
  height: 15px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 15px !important; /* Ensuring the text is vertically centered */
  margin-left: 5px !important;
  margin-right: 8px !important; /* Adding space between the icon and the dropdown */
  vertical-align: middle !important; /* Aligning the icon vertically */
}


.btn-custom {
  background-color: #384d60;
  border-color: #384d60;
  color: white;
}

.provider-profile-container {
  text-align: left;
}

.provider-profile-link {
  background-color: #384d60; 
  color: white; /* Text color white */
  border: none; /* Remove default button border */
  padding: 2px 5px; /* Padding to make it look like a button */
  border-radius: 2px; /* Rounded corners */
  text-decoration: none; /* Remove underline from text */
  display: inline-block; /* Align inline with other elements */
  margin-top: 2px; /* Adjust margin as needed */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color and text color */
}

.provider-profile-link:hover {
  background-color: #0b5ed7; 
  color: white !important;
}

.provider-profile-link:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: none; /* Remove any box shadow on focus */
  background-color: #384d60; /* Maintain background color on focus */
  color: white; /* Maintain text color on focus */
}

.modal-header-custom {
  background-color: #2f4050;
  color: white;
}

.modal-button-custom {
  background-color: #2f4050;
}

/* Additional styles */
button.btn-custom, .pagination .page-item.active .page-link {
  background-color: #384d60;
  border-color: #384d60;
  color: white;
}

.pagination .page-link {
  color: #384d60;
}

/* Additional styles for text color. Search "Number of providers:" in ProviderDirectory.js */
.text-custom-color {
  color: #384d60;
  font-weight:600;
}

/* Modal close button color */
.modal-header .btn-close {
  background-color: #ffffff !important;
  border: none;
}

.modal-header .btn-close:hover {
  background-color: #ffffff !important;
}

.modal-header .btn-close:before {
  color: #384d60;
}

/* Provider Search form */
.provider-search-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px; /* Adjusted gap for reduced spacing */
}

@media (max-width: 768px) {
  .provider-search-form {
    flex-direction: column;
    align-items: stretch;
  }

  .provider-search-form input,
  .provider-search-form button {
    width: 90%; /* Adjusted width for better appearance on mobile */
    margin: 5px auto; /* Centered with auto margins */
  }

  .provider-search-label {
    display: block;
    width: 90%;
    margin: 5px auto; /* Centered with auto margins */
    text-align: left; /* Align text to the left for consistency */
  }

  .provider-search-error {
    text-align: center; /* Center the error message */
    width: 90%;
    margin: 5px auto; /* Centered with auto margins */
  }
}

.providersearch {
  width: 25%; /* Adjusted margin for reduced spacing */
}

.provider-search-button {
  width: auto; /* Adjusted width for the Search button */
  margin: 5px 0; /* Adjusted margin for reduced spacing */
}

/* Make the provider results table responsive on mobile */
@media (max-width: 768px) {
  .table {
    width: 100%;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-bordered {
    border-color: #333; /* Darker border color for the table */
  }

  .table-bordered td, .table-bordered th {
    border-color: #333; /* Darker border color for table cells */
    word-wrap: break-word;
    white-space: normal;
    display: block;
    width: 100%;
    padding: 10px;
  }

  /* Adjust the padding for table cells to reduce spacing on mobile */
  .table-bordered td, .table-bordered th {
    padding: 10px;
  }

  /* Ensure buttons and links fit properly within table cells */
  .provider-profile-container {
    text-align: center;
    margin-top: 10px;
  }

  .provider-profile-link {
    width: 100%;
  }
}


/*=========================End Provider Directory CSS============================================================================================*/
