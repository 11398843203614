section {
  display: block;
}

footer {
  display: block;
  align-items: center;

  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

h2 {
  
  margin-top: 0;
  font-size: 1.6rem;
  font-weight: 700;
}
.top-title-footer{
    color: white;
}

.outerContainer.footerRow {
  background-color: #ebebeb;
  border-top: 1px solid #dedede;
  font-size: 15px;
}

.outerContainer.footerRow.Container {
  background-color: transparent;
  border: transparent;
}
.Combo-Nav footer .footer-brand {
  font-size: 22px;
  line-height: 16px;
  text-align: left;
  color: black;
  padding: 15px 0 0 10px;
  margin: -10px 0 10px 15px;
  border-left: 4px solid #19c07e;
}

.Combo-Nav footer .footer-brand small {
  font-size: 13px;
  display: block;
  margin-bottom: 4px;
}
nav {
  display: block;
}
* {
  box-sizing: border-box;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

@media only screen and (min-width: 100px) {
  footer a:visited {
    color: black;
  }
}
a:link {
  text-decoration: none !important;
}


@media only screen and (min-width: 100px) {
  .footerRow .unbridledRow img {
    width: 120px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 100px) {
  .footerRow > .container {
    background: #efefef;
    text-align: center;
    border-top: 1px solid #e2e2e2;
    color: black;
    padding: 10px 0;
  }
}

.subscribeCTA {
  background-color: #2f4050;
  /* margin: 40px -15px -20px -15px; */
  padding: 40px 0 30px;
}
/* .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} */

.subscribeCTA .row {
  color: #ffff;
  text-align: center;
}

.col-sm-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.scroll-up{
  color: white;
  text-align: center;
}
